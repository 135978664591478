.tooltip {
	display: inline-block;
	vertical-align: top;
	position: relative;

	&::before {
		content: url('../images/design/icon-info-circle-colored.svg');
		margin-left: .25em;
		size: 10px;
		cursor: pointer;
		display: block;
	}
}

.tooltip__content {
	@extend %formMessage;
	
	position: absolute;
	z-index: 10;
	opacity: 0;
	visibility: hidden;

	max-width: 280px;
	@media (width <= 350px) {
		max-width: calc(80vw);
	}
	
	transition: opacity 100ms ease-out,
		visibility 100ms ease-out,
		transform 100ms ease-out;

	/* overrides */
	font-weight: 400;
	text-transform: none;	
	
	&.isActive {
		z-index: 100;
		opacity: 1;
		visibility: visible;
		transform: translateY(0);

		transition: opacity 200ms ease-out,
			visibility 200ms ease-out,
			transform 200ms ease-out;
	}
}

