/* --------------------------------------------------------
 * Reset
 * -------------------------------------------------------- */
@import 'normalize.css'; 

html { box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }

sup, sub { line-height: 0; }
dfn { font-style: italic; }
del { color: #666; }
pre { white-space: pre; }
pre, code, tt { font: 1em/1.5 'andale mono', 'lucida console', monospace; }
strong { font-weight: 700; }
em { font-style: italic; }
small { font-size: 0.833em; } 