/* --------------------------------------------------------
	Form Columns 
	
	NOTE:
		[20161020 CROST]
		DO NOT add formColumns--x directly to <FIELDSET> as webkit browsers have a bug and do not render correctly.
		http://stackoverflow.com/questions/27369754/css-flexbox-in-a-form#27369876
		https://bugs.chromium.org/p/chromium/issues/detail?id=375693
		https://bugs.chromium.org/p/chromium/issues/detail?id=262679
-------------------------------------------------------- */
[class*='formColumns--'] {
	display: flex;
	margin: 0 -10px;

	flex-wrap: wrap;
}
[class*='formColumns--']::after,
[class*='formColumns--']::before {
	content: '';
	display: table;
	width: 100% /* safari */;
}
[class*='formColumns--']::after {
	clear: both;
}
[class*='formColumns--'] > * {
	float: left;
	margin: * 10px;
}
[class*='formColumns--2'] > * {
	width: calc(50% - 20.5px); /* The 0.5px solves a rounding issue. */
}
[class*='formColumns--3'] > * {
	width: calc(33.3333% - 2 * 10px);
}

@media (width <= 460px) {
	[class*='formColumns--'] {
		display: block;
	}
	[class*='formColumns--2'] > * {
		float: none;
		width: calc(100% - 2 * 10px);
	}
}

/*	City State Zip
-------------------------------------------------------- */
.formColumns--CSZ .fieldArea {
	display: block;
	border: none;
}
.formColumns--CSZ .cityFieldArea 	{ width: calc(50% - 2 * 10px); }
.formColumns--CSZ .stateFieldArea 	{ width: calc(30% - 2 * 10px); }
.formColumns--CSZ .zipCodeFieldArea { width: calc(20% - 2 * 10px); }

.formColumns--CSZ .htmlInput [name],
.formColumns--CSZ .htmlSelectOneMenu [name] {
	overflow: hidden;
}

@media (width <= 530px) {
	.formColumns--CSZ > *,
	.formColumns--CSZ > *:last-child,
	.formColumns--CSZ .fieldArea {
		float: none;
		width: calc(100% - 2 * 10px);
	}
}