@media print {
	.screen--calc,
	.screen--results {
		#app {
			position: static !important;
		}		
		.pageHeader,
		[class*="main--"],
		.results__printReport,
		.technicalServiceLink,
		[class*="footer--"] {
			display: none !important;
		}
		.main--results {
			transform: none !important;
			height: auto !important;
			display: block !important;
		}
	}
}