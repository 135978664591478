.btn,
.btn > * {
	position: relative;
	z-index: 2;
}
.btn {
	@mixin betterFontRendering;
	display: inline-block;
	cursor: pointer;
	padding: 0;
	vertical-align: middle;
	text-decoration: none;
	text-transform: uppercase;

	line-height: 1.2;
	text-align: center;
}
.btn > * {
	cursor: inherit;
	font: inherit !important;
	letter-spacing: inherit !important;
	display: flex; /* needed when contains error icon */
	justify-content: center; /* needed when contains error icon */
	height: inherit;
	margin: 0;
	color: inherit;
	border: none;
	border-radius: 0;
	background: none;
	text-shadow: inherit;
	text-decoration: inherit;
	text-transform: inherit;
	appearance: none;
}
button.btn {
	border: 0;
	outline: 0;
}

/* Skin
-------------------------------------------------------- */
$button_fontSize: 			15px;
$button_padding-V-top: 		11px;
$button_padding-V-btm: 		11px;
$button_padding-H: 			30px;

$primaryButton_color_text: 			#fff;
$primaryButton_background: 			$gradient_red;
$primaryButton_background-hover: 	linear-gradient(to bottom, color($color_red lightness(+5%)) 0%, color($color_red-d9 lightness(+5%)) 100%);
$primaryButton_background-active:	linear-gradient(to bottom, color($color_red lightness(-5%)) 0%, color($color_red-d9 lightness(-5%)) 100%);

$secondaryButton_color_text:		$color_gray-0D;
$secondaryButton_color_text-hover:	$color_linkHover;
$secondaryButton_color_text-active:	$color_linkActive;
$secondaryButton_background: 		#fff;
$secondaryButton_background-hover: 	#fff;
$secondaryButton_background-active:	#fff;

.btn {
	color: $primaryButton_color_text;
	background: $primaryButton_background;
	font-family: $fontHeadings;
	letter-spacing: .04em;

	&::before {
		content: '';
		position: absolute 0;
		border-radius: inherit;
		background: $primaryButton_background-hover;
		
		opacity: 0;
		transition: opacity 200ms ease;
	}
	&:focus,
	&:hover {
		&::before {
			opacity: 1;
		}
		&,
		> * {
			color: $primaryButton_color_text;
		}
	}
	&:active {
		background: $primaryButton_background-active;
		background-clip: content-box;
		
		&,
		> * {
			color: $primaryButton_color_text;
		}
		&::before {
			opacity: 0;
		}
	}
	&--large {
		width: 100%;
		font-size: 18px;

		> * {
			min-height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;			
		}		
	}
	&--secondary {
		color: $secondaryButton_color_text;
		background: $secondaryButton_background;
		transition: $linkTransition;

		* {
			transition: inherit;
		}
		&::before {
			background: $secondaryButton_background-hover;
		}
		&:focus,
		&:hover {
			&,
			> * {
				color: $secondaryButton_color_text-hover;
			}
		}
		&:active {
			background: $secondaryButton_background-active;

			&,
			> * {
				color: $secondaryButton_color_text-active;
			}
		}		
	}
	&--isDisabled {
		cursor: not-allowed;
		
		&, > * {
			background: #ccc !important;
			color: #8c8c8c !important;
		}
	}
	&.containsError {
		> * {
			&::before {
				content: url('../images/design/icon-exclamation-triangle-colored.svg');
				width: calc(15em/16);
				max-width: @width;
				flex: 1 0 auto;
				line-height: 0;
				margin-right: calc(5em/14);
			}
		}
	}		
	> * {
		width: 100%;
		padding: $button_padding-V-top $button_padding-H $button_padding-V-btm;
	}
}

/* 4. Wait component
-------------------------------------------------------- */
.btn {
	overflow: hidden;
}
@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(359deg); }
}

.btn__waitingIndicator {
	position: absolute;
	transform: translateY(100%);
	transition: transform 250ms ease;

	&::before {
		content: '';
		background: url(../images/design/icon-plaudit-not-available.svg) no-repeat;
		font-size: 1.2em;
		position: absolute 50% 0 0 50%;

		width: 1em;
		height: 1em;
		margin: -.5em 0 0 -.5em;
		padding: 0;
	}
	&::after {
		transform: translateY(0px);
		transition: none;
	}
}

.btn--isWaiting {
	&.submissionTrigger > .btn__waitingIndicator::before {
        animation: spin 1s infinite linear;
        background: url(../images/design/icon-plaudit-refresh.svg) no-repeat;
	}
	&:hover > .btn__waitingIndicator,
	  > .btn__waitingIndicator {
		transform: translateY(0px);
		cursor: wait;
		position: absolute 0;
		z-index: 12;

		background: $primaryButton_background;
		text-align: center;
	}
}
