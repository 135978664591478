.form--inlineLabels .fieldArea {
	display: flex;
}
.form--inlineLabels label {
	min-width: 7em;
	margin-right: 20px;
}
.form--inlineLabels [name] {
	max-width: calc(100% - ($form_fontSize_label * 7 + 20px));
}
.form--inlineLabels .formColumns--CSZ .stateFieldArea label,
.form--inlineLabels .formColumns--CSZ .zipCodeFieldArea label {
	min-width: 0;
}
.form--inlineLabels .formColumns--CSZ .stateFieldArea [name],
.form--inlineLabels .formColumns--CSZ .zipCodeFieldArea [name] {
	max-width: none;
}

.form--inlineLabels .htmlSelectOneRadio > div > div,
.form--inlineLabels .htmlSelectManyCheckbox > div > div {
	display: inline-block;
	padding: 3px 0;
}

.form--inlineLabels .htmlSelectOneRadio div label {
	padding: 5px 0;
}
