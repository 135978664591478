/* --------------------------------------------------------
 *
-------------------------------------------------------- */
html {
	background: $color_background;
	color: $color_text;
	font: 400 16px/1.3333 $fontDefault;
	/* overflow-y: hidden; */ /* makes screens not scrollable in Edge */
	overflow-x: hidden; /* not setting overflow-y:hidden introduces bug - header is not sticky after scrolling over 100vh (noticeable on Technical Services screen) */
}
[v-cloak] {
	display:none !important;
}

::selection {
	background-color: $color_textSelectionBg;
	color: $color_black;
}

/* Spacing
-------------------------------------------------------- */
p, ul, ol, dl, blockquote {
	margin: 0 0 1em;
}
ul, ol {
	padding-left: calc(12em/16);

	li {
		margin: 0 0 .5em;
	}
	ul, ol {
		margin: 0;

		li {
			margin-top: .5em;
		}
	}
}

/* Anchors
-------------------------------------------------------- */
a {
	color: $color_link;
	background-color: transparent/* IE10 */;
	text-decoration: underline;
	transition: $linkTransition;

	/* &[href^="tel:"] {
		color: inherit;
		text-decoration: none;
	} */

	&:hover,
	&:focus { color: $color_linkHover; }
	&:active { color: $color_linkActive; }
}

/* Headings

	Having each heading extend case and spacing allows the
	heading to be used in other places (ex: card styles)
	without the need to override the default styling of the
	heading tag
-------------------------------------------------------- */
@define-mixin heading $fontWeight, $fontSize, $lineHeight, $fontFamily, $color, $textTransform, $margin, $letterSpacing: $fontHeadings_letterSpacing {
	font: $fontWeight $fontSize/$lineHeight $fontFamily;
	color: $color;
	text-transform: $textTransform;
	margin: $margin;
	letter-spacing: $letterSpacing;
}

%h1 { @mixin heading 400, 24px, $fontHeadings_lineHeight, $fontHeadings, $color_headings, none, 0 0 6px; }
%h2 { @mixin heading 400, 18px, $fontHeadings_lineHeight, $fontHeadings, $color_headings, none, 0 0 6px; }
%h3 { @mixin heading 400, 16px, $fontHeadings_lineHeight, $fontHeadings, $color_headings, none, 0 0 6px; }
%h4 { @mixin heading 400, 15px, $fontHeadings_lineHeight, $fontHeadings, $color_headings, none, 0 0 6px; }
%h5 { @mixin heading 400, 10px, $fontHeadings_lineHeight, $fontHeadings, $color_headings, none, 0; }
%h6 { @extend %h5; }

%allHeaders {
	&:first-child { margin-top: 0; }

	a {
		text-decoration: none;

		&:not(:hover):not(:focus):not(:active) {
			color: inherit;
		}
	}
}
h1 { @extend %allHeaders; @extend %h1; }
h2 { @extend %allHeaders; @extend %h2; }
h3 { @extend %allHeaders; @extend %h3; }
h4 { @extend %allHeaders; @extend %h4; }
h5 { @extend %allHeaders; @extend %h5; }
h6 { @extend %allHeaders; @extend %h6; }

/* Horizontal Rule
-------------------------------------------------------- */
hr {
    display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid $color_horizontalRule;

	margin: 20px 0;
}

/* Content related mixins used throughout site
-------------------------------------------------------- */
@define-mixin ulCustomBullets {
	list-style: none;

	& > li,
	& ul > li {
		position: relative;

		&::before {
			content: $unicode-black-circle;
			position: absolute;
			left: -1em;

			color: inherit;
			font-family: Helvetica, Arial, sans-serif;
			font-size: 1em;
			line-height: calc(21/16);
		}
	}
	& ul > li:before { content: $unicode-white-circle; }
	& ul ul > li:before { content: $unicode-black-circle; }
	& ul ul ul > li:before { content: $unicode-white-circle; }
}
main ul {
	@mixin ulCustomBullets;
}