@define-mixin individualAccordionPanel {
	@mixin box;
	padding: * 13px;
	
	+ .accordionWrapper {
		margin-top: 8px;
	}
	
	.accordion__heading {
		/* make entire panel clickable */
		border-left: 10px solid transparent;
		border-right: @border-left;
		margin: 0 -10px;
	}
	.accordion__body {
		position: relative;
		
		&::before {
			border-top: 1px solid $color_gray-e6;
		}
		&[class*="formColumns"] {
			&::before {
				width: calc(100% - 2 * 10px);
				position: relative;
				left: 10px;
			}
		}		
	}
}

/* 	Base Styles
-------------------------------------------------------- */
.accordionWrapper {
	.accordion__heading {
		margin: 0;
		padding: 13px *;

		legend {
			color: inherit;
			margin: 0;
		}		
	}
	.accordion__body {
		/* alternative to using padding, which doesn't collapse when accordion is closed */
		&::before {
			content: '';
			display: block;
			height: 17px;
		}
		&::after {
			content: '';
			display: block;
			height: 13px;
		}
	}

	/* Contains Error */
	&.containsError {
		/* .accordion:not(.accordion--active) .accordion__heading {
			border-bottom-color: transparent;
		} */
		legend {
			display: flex;
			align-items: baseline;
	
			&::after {
				content: url('../images/design/icon-exclamation-triangle-colored.svg');
				display: inline-block; /* Safari */
				width: calc(10em/15);
				max-width: @width;
				margin-left: calc(7em/15);
				flex: 1 0 auto;	
				line-height: 0;
			}
		}
	
		/*
		@media (width > $screen_stackResults) {
			border: 1px solid $form_color_error;
			border-radius: $borderRadius;
			padding: 0 10px;
			margin: 0 -10px;
		}
		*/
		/*
		@media (width <= $screen_stackResults) {
			&[class] { /* &[class] needed for specificity * /
				border-color: $form_color_error;
			}
		}
		*/
	}	
}

/* 	Accordions on .screen--calc
-------------------------------------------------------- */
.screen--calc {
	.main--calc {
		user-select: none;
	}
	.accordionWrapper {
		@media (width > $screen_stackResults) {
			.accordion__heading {
				border-bottom: $headingBorder;
				
				&::before,
				&::after {
					right: 0;
				}
			}
		}
		@media (width <= $screen_stackResults) {
			@mixin individualAccordionPanel;
		}
	}
}

/* 	Accordions on .screen--technicalServices
-------------------------------------------------------- */
.screen--technicalServices .accordions {
	padding: 0;
	max-width: $contentContainerMaxWidth;
	margin: 0 auto; /* To center in parent */

	li { 
		margin: 0; 
		padding: 0; 
		&::before { display: none; } 
	}
	.accordionWrapper {
		@mixin individualAccordionPanel;
	}
	.accordion__body {
		> a {
			text-decoration: none;
			display: inline-block;
			margin-bottom: 10px;
		}
	}
}

/* 	Styles applied only when JS is enabled
-------------------------------------------------------- */
.js .accordion {
	&__bodyWrap {
		overflow: hidden;
		transition: height 500ms $easeOut 0ms;
	}
	&__heading {
		position: relative;
		cursor: pointer;
		/* transition: $linkTransition; */
		transition: color 400ms $easeOut;
		padding-right: 30px;

		&::before,
		&::after {
			content: '';
			position: absolute * 0 * *;
			display: block;
			background-color: $color_red;
			width: 12px;
			height: 2px;
			transition: transform 300ms $easeOut 0ms;
		}
		&::before {
			top: 50%;
			transform: rotate(180deg);
		}
		&::after {
			top: 50%;
			transform: rotate(90deg);
		}
		&:hover,
		&:focus {
			color: $color_linkHover;
		}			
		&:active {
			color: $color_linkActive;
		}
	}
	&--active {
		.accordion__heading {
			&::before,
			&::after {
				transform: none;
			}
		}
	}		
}