.u-noWrap {
	white-space: nowrap;
}
.u-avoidWrap {
	white-space: nowrap;
	@media (width <= 370px) {
		white-space: initial;
	}
}


/* --------------------------------------------------------
 * Utility mixins and place holders. Only generic ones that
 * could be argued as something CSS 'should provide'.
 * ------------------------------------------------------- */

@define-mixin anchorEvents {
	&.active, &:active, &:focus, &:hover {
		@mixin-content;
	}
}

/*
 * Mixin to easily make any property responsive. Do include units.
 * Example:
 * 		@mixin responsive font-size, 1px, 100px, 100px, 1100px;
*/
@define-mixin responsive $property, $minSize, $maxSize, $minWidth, $maxWidth {
	$minSize_strip: 	strip($minSize);
	$maxSize_strip: 	strip($maxSize);
	$minWidth_strip: 	strip($minWidth);
	$maxWidth_strip: 	strip($maxWidth);
	$(property): fallback(calc($minSize + ($maxSize_strip - $minSize_strip) * ((100vw - $minWidth) / ($maxWidth_strip - $minWidth_strip))), $minSize);

	@media (min-width: $maxWidth) {
    	$(property): $maxSize;
	}
	@media (max-width: $minWidth) {
	    $(property): $minSize;
	}
}

@define-mixin clearfix {
	&::before,
	&::after {
		content: '';
	}
	&::after {
		display: table;
		clear: both;
	}
}

@define-mixin visuallyHidden {
	position: absolute !important;
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0 !important;
	border: 0 !important;
	height: 1px !important;
	width: 1px !important;
	overflow: hidden;
}
