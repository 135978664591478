/* --------------------------------------------------------
	Default styling of form elements.
-------------------------------------------------------- */
button, input, optgroup, select, textarea {
	font-family: $fontDefault; /* normalize.css override */
}
::placeholder {
	color: $form_color_placeholderText;
}
fieldset {
	margin: 0;
	padding: 0;
	border: none;
}
legend {
	@extend %h4;
}
label {
	display: block;
}
input {
	outline: none;
}
option {
	padding: 5px;
}

/* --------------------------------------------------------
	Basics
-------------------------------------------------------- */
.requiredMarker {
	color: $form_color_requiredMarker;
	display: inline-block;
	margin: 0 .5ex;
	display: none;
}


/* --------------------------------------------------------
	FieldAreas

		* FieldArea: Inputs
		* FieldArea: Textarea
		* FieldArea: Select
		* FieldArea: Radios and Checkboxes

	Default styling is label stacked over input

	States:
	fieldArea--isHover
	fieldArea--isFocus
	fieldArea--isFilled
-------------------------------------------------------- */
.fieldArea {
	font-size: 15px;
	line-height: 1.2;
	position: relative; /* needed for inline messages */
	margin-bottom: 17px;
	color: $form_color;
}
%label {
	font-weight: 700;
	font-size: $form_fontSize_label;
	line-height: 1.2;
	margin: 0 0 6px;
	text-transform: uppercase;
	letter-spacing: .06em;
	color: $form_color_label;
	position: relative; /* needed for tooltips */
}
.fieldArea label {
	@extend %label;
}


/* 	FieldArea: Inputs
	Includes htmlInput, htmlTextarea
-------------------------------------------------------- */
[class*='htmlInput'] {
	color: $color_gray-80;

	> div {
		display: flex;
		align-items: center;
	}
	input {
		color: $form_color;
		min-width: 0;
		height: 100%;
		flex: 1 1 5%;
		margin: 0 2px;
		padding: 0;
		outline: 0;
		border: 0;
		border-radius: 0;
		background: transparent;
	}
}
[class*='htmlInput'] > div,
[class*='htmlTextarea'] [name],
.htmlSelectOneMenu select[name],
.regionComponent [name] {
	font-family: inherit;
	font-size: 1em;
	width: 100%;
	height: $form_height;
	padding: 5px 10px;
	color: inherit;
	border: 1px solid $form_color_border;
	border-radius: $borderRadius;
	outline: none;
	background: #fff;

	appearance: none;
}
[class*='htmlInput'] input:hover,
[class*='htmlTextarea'] [name]:hover,
.htmlSelectOneMenu select[name]:hover,
.regionComponent input[name]:hover {
	background-color: #FCFCF5;
}
[class*='htmlInput'] input:focus {
	border-top: 1px solid transparent;
	border-bottom: 1px dashed $form_color_border;
	background: transparent;
}
[class*='htmlTextarea'] [name]:focus,
.htmlSelectOneMenu select[name]:focus,
.regionComponent input[name]:focus {
	outline: none;
	box-shadow: 0 2px 0 0 $form_color_border;
	background-color: #fff;
}
[class*='htmlInput'].containsError input:focus,
[class*='htmlTextarea'].containsError [name]:focus,
.htmlSelectOneMenu.containsError select[name]:focus,
.regionComponent.containsError [name]:focus {
	/* box-shadow: 0 2px 0 0 $form_color_error; */
	/* border-bottom-color: $form_color_error; */
}


/* 	FieldArea: Textarea
-------------------------------------------------------- */
.htmlTextarea textarea[name] {
	overflow: auto/* IE - Prevents scroll bars */;
	min-height: 6em;
	display: block /* Chrome sets this to inline-block */;
}


/* 	FieldArea: Select
-------------------------------------------------------- */
select {
	cursor: pointer;
	display: block;
	width: calc(100% - 20px);
	min-width: 0;
	height: 100%;
	flex: 1 1 auto;
	margin: 0 2px;
	padding: 0;
	outline: 0;
	border: 0 solid transparent;
	border-radius: 0;
	background: transparent;

	white-space: nowrap;
	text-transform: inherit;
	text-overflow: ellipsis;

	&:hover {
		background-color: #FCFCF5;
	}
	&:focus {
		background: transparent;
	}
}


/* 	FieldArea: Radios and Checkboxes
-------------------------------------------------------- */
.fieldArea [name][type='radio'],
.fieldArea [name][type='file'],
.fieldArea [name][type='checkbox'] {
	width: auto;
	max-width: 100%;
}
/* tables layout */
[class*='htmlSelect'] {
	& table,
	& th,
	& td {
		width: auto;
		margin-bottom: 0;
		padding: 0 20px 0 0;
		border: 0;
		background: none;
	}
	& .lineDirection td {
		display: inline-block;
	}
	& .lineDirection label {
		display: inline-block;
		margin: 0 5px;
	}
}
.htmlSelectManyCheckbox {
	& .lineDirection td {
		position: relative;
	}

	& .lineDirection td label {
		margin-left: 25px;
	}
	& .lineDirection td [name] {
		position: absolute;
		top: 0;
		left: 0;
		margin: 3px /* browser glow */;
	}
}
.htmlSelectOneRadio,
.htmlSelectManyCheckbox,
.htmlSelectBooleanCheckbox {
	& input + label::before {
		content: "";
		display: inline-block;

		width: 15px;
		height: 15px;
		margin: 0 15px 0 3px;

		border: 1px solid $form_color_border;
		background: #fff;
		vertical-align: middle;
	}
}
.htmlSelectManyCheckbox,
.htmlSelectBooleanCheckbox {
	position: relative;
	display: block;
	border: none;

	& div {
		position: relative;
	}
	& input {
		position: absolute;
		top: 0;
		left: -999em;
		margin: 3px /*glow*/;
	}
	& input + label {
		cursor: pointer;
		font-weight: 400;
		line-height: 18px;
		display: inline-block;
		margin: * * 0 0;
		padding: 5px 0;
	}
	& input + label::before {
		margin-top: -4px;
		background-repeat: no-repeat;
	    background-position: 50% 50%;
	    background-size: 12px;
	    box-shadow: 0 0 0 1px #fff inset;
	    transition: background 200ms ease;
	}
	& input + label:hover::before,
	& input:focus + label::before {
		border-color: $form_color_accent;
		background-color: $form_color_accent;
	}
	& input:checked + label::before,
	& input:checked + label:hover::before {
		border-color: $form_color_accent;
		background-color: $form_color_accent;
		background-image: url(../images/design/icon-form-checkmark.svg);
		box-shadow:
		    0 0 0 1px $form_color_accent,
		    0 0 0 2px #fff,
		    0 0 0 3px $form_color_accent;
	   transition-property: background, box-shadow;
	}
}

.htmlSelectOneRadio input {
	position: absolute;
	left: -999em;

	& + label {
		cursor: pointer;
	}
	& + label::before {
		border-radius: 100%;
		background-clip: content-box;
		box-shadow: 0 0 0 3px #fff inset;
	}
	&:checked + label::before {
		border-color: $form_color_accent;
		background-color: $form_color_accent;
	}
	&:focus + label::before,
	&:hover + label::before {
		border-color: $form_color_accent;
		filter: drop-shadow(0 2px 0 color($form_color_border a(35%)));
	}
}


/* Icons
	NOTE: you can easily change the color of the arrow in the svg
-------------------------------------------------------- */
$selectArrow_width: 10px;
$selectArrow_distanceFromRightEdge: 15px;

.htmlSelectOneMenu select[name],
.regionComponent--dropdown select[name] {
	padding-right: 20px;
	background-image: svg-load('../../../webapp-static/assets/images/design/icon-form-select.svg', fill=#BCBDC0);
	background-repeat: no-repeat;
	background-position: calc(100% - 10px) 50%;
	background-size: $selectArrow_width;
}
.htmlSelectOneMenu select[name]::-ms-expand,
.regionComponent select[name]::-ms-expand {
	display: none;
}



/* Support
-------------------------------------------------------- */
.IE9,
.ltIE9 {
	& .htmlSelectOneMenu select[name],
	& .regionComponent select[name] {
		padding-right: 5px;
	}
	& .htmlSelectOneMenu::after,
	& .regionComponent--dropdown::after {
		display: none;
	}
}
.ltIE9 {
	& .fieldArea [name][type='checkbox'] {
		max-width: none;
	}
	& .htmlSelectBooleanCheckbox [name][type='checkbox'] {
		position: static;
		float: left;
		height: auto;
		margin: 3px /* browser glow */;
		padding: 0;
	}
	& .htmlSelectBooleanCheckbox label {
		margin-left: 0;
		vertical-align: middle;
	}
	& .htmlSelectBooleanCheckbox label::before {
		display: none;
	}
}