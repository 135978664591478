/* Inline Message */
.message {
	@extend %formMessage;
	white-space: normal;
	position: absolute 100% 0 *;
	z-index: 100;	
}

/* .fieldArea[class~="containsError"] label { color: $form_color_error; }
.fieldArea[class~="containsFatal"] label { color: $form_color_fatal; }
.fieldArea[class~="containsInfo"]  label { color: $form_color_info; }
.fieldArea[class~="containsWarn"]  label { color: $form_color_warn; } */

.fieldArea {
	&[class*="contains"] {
		label::after {
			line-height: 0;
			width: 1em;
			margin-left: .5em;
			display: inline-block;
		}
	}
}
.fieldArea[class~="containsError"] label::after { content: url('../images/design/icon-exclamation-triangle-colored.svg');	}
.fieldArea[class~="containsFatal"] label::after { content: url('../images/design/icon-fatal-colored.svg');					}
.fieldArea[class~="containsInfo"]  label::after { content: url('../images/design/icon-info-circle-colored.svg');			}
.fieldArea[class~="containsWarn"]  label::after { content: url('../images/design/icon-question-circle-colored.svg');		}

.fieldArea[class~="containsError"] > div { border-color: $form_color_error; }
.fieldArea[class~="containsFatal"] > div { border-color: $form_color_fatal; }
.fieldArea[class~="containsInfo"]  > div { border-color: $form_color_info; }
.fieldArea[class~="containsWarn"]  > div { border-color: $form_color_warn; }

/* .fieldArea[class~="containsError"] > div:focus { box-shadow: 0 2px 0 0 $form_color_error; } */

.js .fieldArea .message {
	visibility: hidden;
	opacity: 0;
	transform: translateY(4px);
	transition-property: opacity, visibility, transform;
	transition-duration: 100ms;
	transition-timing-function: ease-out;
}
/* .js .fieldArea--isFocus .message,
.js .fieldArea--isHover .message { */
.js .fieldArea > div:focus .message,
.js .fieldArea > div:hover .message {
	visibility: visible;
	opacity: 1;
	transform: translateY(0);
	transition-duration: 200ms;
}



/* Block Message */
.messages {
	position: relative;
	padding: 15px 20px;
	margin: 0 0 8px;
	color: $color_gray-33;
	font-size: 14px;
	display: flex;
	border-radius: $borderRadius;
	background: #fff;

	&::before {
		content: '';
		width: 15px;
		max-width: @width;
		flex: 1 0 auto;
		margin-right: 10px;
		z-index: 1;
	}
	&::after {
		content: '';
		position: absolute 0;
	}
	&__text {
		z-index: 1;

		> :last-child,
		> ul:last-child li:last-child {
			margin-bottom: 0;
		}
	}	
}
ul.message__text {
	margin-bottom: 0;
}
@define-mixin messages $color {
	border: 1px solid $color;

	&::after {
		background: color($color a(.05));
	}
}
.messages[class*='error']	{ @mixin messages $form_color_error;	&::before { content: url('../images/design/icon-exclamation-triangle-colored.svg'); } }
.messages[class*='fatal']	{ @mixin messages $form_color_fatal;	&::before { content: url('../images/design/icon-fatal-colored.svg'); } }
.messages[class*='info']	{ @mixin messages $form_color_info;		&::before { content: url('../images/design/icon-info-circle-colored.svg'); } }
.messages[class*='warn']	{ @mixin messages $form_color_warn;		&::before { content: url('../images/design/icon-question-circle-colored.svg'); } }