.pageHeader > * {
	display: none;
}


/* 	Screens: Shared styling for all
-------------------------------------------------------- */
@define-mixin displayScreen $screen, $screenMobile: $screen_mobile {
	.main--$screen {
		padding-top: 18px;
		padding-bottom: 40px;
		position: absolute;
		width: 100%;

		overflow: hidden;
		height: 1px;

		@media (width > $screenMobile) {
			padding: * $defaultSpacing-H-desktop;
		}
		@media (width <= $screenMobile) {
			padding: * $defaultSpacing-H-mobile;
		}			

		transition: transform 250ms $easeMaxOut 0ms,
					opacity 250ms $easeMaxOut 0ms;

		transform: translate(100vw, 0);
		@media (width > $screen_stackResults) {
			opacity: 0;
		}
	}
	.footer--$screen {
		width: 100%;
		transition: transform 250ms $easeMaxOut 0ms;
		transform: translate(0, 100%);
	}

	.screen--$screen {
		.header--$screen {
			display: block;
		}
		.main--$screen,
		.footer--$screen {
			/* transform: none; */ /* Breaks in iOS Safari - calc screen will not be visible if navigating to it from the install or results screens */
			transform: translateZ(0);
			opacity: 1;
		}
		.main--$screen {
			overflow: auto;
			height: auto;
		}
		.footer--$screen {
			transition: transform 400ms $easeOut 200ms;
		}
	}
	.screenPrevious--$screen {
		.main--$screen {
			z-index: -1;
			transform: translate(-20vw, 0);
			opacity: 0;
			height: auto;
		}
	}
}

@mixin displayScreen calc;
@mixin displayScreen results, $screen_stackResults;
@mixin displayScreen technicalServices;
@mixin displayScreen settings;
@mixin displayScreen settings_addMaterial;
@mixin displayScreen settings_saveMaterial;
@mixin displayScreen settings_addOrifice;
@mixin displayScreen settings_saveOrifice;
@mixin displayScreen settings_currency;
@mixin displayScreen install;

/* 	Screen: Calc and Results
-------------------------------------------------------- */
#app {
	position: absolute 0;
	@media (width <= $screen_stackResults) {
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
}
@media (width > $screen_stackResults) {
	.main--results,
	.main--calc {
		transition: opacity 250ms $easeFastInMaxOut 0ms;
		opacity: 0;
	}
}
.screen--calc,
.screen--results {
	@media (width > $screen_stackResults) {
		#app {
			height: 100vh;
			display: flex;
			flex-direction: column;
		}
		main,
		.calcAndResultsContainer {
			flex: 1 1 auto;
			display: flex;
			overflow: hidden;
		}
		.main--results,
		.main--calc {
			position: relative;
			transform: none;
			opacity: 1;
			overflow: auto;
			height: auto;
		}
		.main--results {
			overflow: auto;
			height: auto;
			display: block;
			/* height: 100%; */ /* breaks in Safari */
			overflow-y: auto;
			background: $color_background;
		}
		.main--calc {
			display: block;
			min-width: 370px;
			width: calc(425/1280 * 100%);
			max-width: @width;
			/* height: 100%; */ /* breaks in Safari */
			flex: 1 0 auto;
			overflow: auto;

			.calc__viewResultsBtn {
				display: none; /* hide because all calculations are performed instantly on right side of the page */
			}
		}
	}
}

