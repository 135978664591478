/* --------------------------------------------------------
	Styling for nested type fieldArea where the input value covers the label when filled.

	NOTES:
		These options override the defaults
		There are overrides for columns as well
-------------------------------------------------------- */
.form--nestedLabels .htmlInput,
.form--nestedLabels .htmlSelectOneMenu {
	border: 1px solid $form_color_border;
	background-color: #fff;

	& label,
	& [name] {
		font: inherit;
		line-height: inherit;
		padding: 6px 8px;
	}
	& label {
		display: block;
		width: 100%;
		height: 100%;
		margin: 0;
		color: $form_color_placeholderText;
		text-align: left;
		white-space: nowrap;
	}
	& [name] {
		position: absolute 0 0 0 *;
		width: fallback(calc(100% - 16ch), 75%);
		height: auto;
		max-height: 100%;
		border: none;
		outline: none;
		transition: width 100ms ease 0ms;
		&:focus { width: 100%; }
	}
	&.isFilled [name] { width: 100%; }
}

.form--nestedLabels .fieldArea:hover [name],
.form--nestedLabels .fieldArea.isHover [name] { background-color: #fff; }

.form--nestedLabels .htmlTextarea {
	display: block;
	border-bottom: 0;

	& [name] { max-height: auto; }
	& label { display: block; }
}
.form--nestedLabels .htmlSelectOneMenu [name] {
	padding-right: 20px;
}
