
$scrollbarControls_bgColor: $color_white;
$scrollbarControls_border: 1px solid $color_background;
$scrollbarControls_size: 10px;
$scrollbarControls__slider_bgColor: $color_gray-e6;
$scrollbarControls__slider--isHover_bgColor: $color_gray-d9;

/* Native scrollbars: Chrome (webkit)
-------------------------------------------------------------- */
body {
	& ::-webkit-scrollbar {
		width: $scrollbarControls_size;
		height: $scrollbarControls_size;
		background-color: $scrollbarControls_bgColor;
	}
	& ::-webkit-scrollbar-corner {
		background-color: color($scrollbarControls_bgColor l(-2%));
	}

	& ::-webkit-scrollbar-track {
		background-color: $scrollbarControls_bgColor;
		border: $scrollbarControls_border ;
	}

	& ::-webkit-scrollbar-thumb {
		background-color: $scrollbarControls__slider_bgColor;
	}
	& ::-webkit-scrollbar-thumb:hover {
		background-color: $scrollbarControls__slider--isHover_bgColor;
	}
}




