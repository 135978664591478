/* --------------------------------------------------------
	Variables
-------------------------------------------------------- */
/* Screen Sizes */
$screen_results-panel2-2col:	1050px;
$screen_mobile:					768px; /* mainly used for decreasing page and "box"/"panel" horizontal padding on small screens */
$screen_stackResults:			760px;
$screen_switchResultsHeader: 	$screen_stackResults; /* should be less than or equal to $screen_stackResults (if greater than, there will be two Hypertherm logos on desktop) */

/* Colors */
$color_red:				#ED1B2E;
$color_red-d9:			#D90F20;
$gradient_red:			linear-gradient(to bottom, $color_red 0%, $color_red-d9 100%);

$color_yellow:			#FFC20F;

$color_white:			#ffffff;
$color_black:			#000000;
$color_gray-0D:			#0D0D0D;
$color_gray-1a:			#1A1A1A;
$color_gray-1c:			#1C1C1C;
$color_gray-33: 		#333333;
$color_gray-66:			#666666;
$color_gray-70:			#707070;
$color_gray-80:			#808080;
$color_gray-d9:			#D9D9D9;
$color_gray-e6: 		#E6E6E6;
$color_gray-e8:			#E8E8E8;
$color_gray-ec:			#ECECEC;
$color_gray-f2: 		#F2F2F2;

$color_text: 			$color_gray-33;
$color_headings:		$color_gray-0D;
$color_background: 		$color_gray-f2;
$color_horizontalRule:	$color_gray-e6;

$color_textSelectionBg:	#faefa0;


/* CSS Ease */
$easeOutCirc: 			cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeInCirc: 			cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeOutCubic: 			cubic-bezier(0.215, 0.610, 0.355, 1.000);

/* Transitions */
$easeFastInMaxOut: cubic-bezier(0.0, 0.4, 0.0, 1);
$easeMaxOut: cubic-bezier(0.0, 0.0, 0.0, 1);
$easeOut: cubic-bezier(0.4, 0.0, 0.2, 1);
$easeInOut: cubic-bezier(0.8, 0.0, 0.2, 1);
$easeIn: cubic-bezier(0.6, 0.0, 0.6, 1);

/* Fonts */
$fontDefault: 'Roboto', sans-serif;
$fontHeadings: 'Oswald', sans-serif;
$fontHeadings_lineHeight: 1.4;
$fontHeadings_letterSpacing: .01em;

/* Links */
$color_link: $color_red;
$color_linkHover: color($color_link lightness(+10%));
$color_linkActive: color($color_link lightness(-10%));

/* Spacing */
$defaultSpacing-H-mobile:			10px;
$defaultSpacing-H-desktop:			15px;

/* Boxes */
$box_padding-H-mobile:				10px;
$box_padding-H-desktop:				20px;
$boxBorder: 1px solid $color_gray-ec;
$borderRadius: 2px;

@define-mixin box $includePadding: false { /* must be mixin since used in @media's */
	border: $boxBorder;
	border-radius: $borderRadius;
	background: #fff;

	@if $includePadding == true {
		@media (width > $screen_mobile) {
			padding: 20px $box_padding-H-desktop;
		}
		@media (width <= $screen_mobile) {
			padding: 16px $box_padding-H-mobile;
		}
	}
}

/* Misc */
$headingBorder: 1px solid $color_gray-e6;
$linkTransition: all 400ms $easeOut;
$contentContainerMaxWidth: 650px;

/* Misc Placeholders and Mixins */
@define-mixin closeButton $size: 12px, $strokeWidth: 2px, $color: #fff {
	font-size: 0;
	margin: 0;
	padding: 0;
	outline: 0;
	border-radius: 0;
	background: transparent;
	cursor: pointer;

	border: 10px solid transparent;
	size: $size;
	box-sizing: content-box;
	transition: opacity 200ms;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: calc(50% - $strokeWidth / 2);
		display: block;
		background-color: $color;
		width: 100%;
		height: $strokeWidth;
		/* transition: background-color 300ms $easeOut 0ms,
					transform 300ms $easeOut 0ms;  */
		transition: transform 300ms $easeOut 0ms;
	}
	&::before {
		transform: rotate(135deg);
	}
	&::after {
		transform: rotate(45deg);
	}
	&:hover,
	&:focus {
		opacity: .85;
	}
	&:active {
		opacity: .65;
	}	
}

$form_color_error: 				$color_yellow;
$form_color_fatal: 				$color_yellow;
$form_color_info: 				#168BBF;
$form_color_warn: 				$color_yellow;


%formMessage--error::before	{ background-image: url('../images/design/icon-exclamation-triangle.svg');	background-color: $form_color_error; }
%formMessage--fatal::before	{ background-image: url('../images/design/icon-fatal.svg');					background-color: $form_color_fatal; }
%formMessage--info::before	{ background-image: url('../images/design/icon-info-circle.svg');			background-color: $form_color_info; }
%formMessage--warn::before	{ background-image: url('../images/design/icon-question-circle.svg');		background-color: $form_color_warn; }	

%formMessage {
	color: $color_gray-33;
	background: #fff;
	box-shadow: 1px 1px 5px rgba(0,0,0,.24);
	font-size: 12px;
	line-height: 1.2;
	display: flex;
	align-items: center;
	padding: 8px 10px 8px 0;
	margin-bottom: 10px;

	&::before {
		content: '';
		width: 38px;
		max-width: @width;
		flex: 1 0 auto;
		margin: -8px 10px -8px 0;
		align-self: stretch;
		display: flex;
		background-size: calc(100% - 2 * 8px) auto;
		background-position: 50% 50%;
		background-repeat: no-repeat;
	}
	&[class*="error"]	{ @extend %formMessage--error; }
	&[class*="fatal"]	{ @extend %formMessage--fatal; }
	&[class*="info"]	{ @extend %formMessage--info; }
	&[class*="warn"]	{ @extend %formMessage--warn; }	

	&__text > :last-child {
		margin-bottom: 0;
	}
	&__link {
		padding: 0;
		outline: 0;
		border: 0;
		background: transparent;
		cursor: pointer;
		font-weight: 500;
		transition: $linkTransition;

		&:hover,
		&:focus {
			opacity: .85;
		}
		&:active {
			opacity: .65;
		}
	}
	&[class*="error"] &__link { color: $form_color_error; }
	&[class*="fatal"] &__link { color: $form_color_fatal; }
	&[class*="info"] &__link { color: $form_color_info; }
	&[class*="warn"] &__link { color: $form_color_warn; }
}


.u-notransition {
	transition: none !important;
}