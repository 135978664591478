/* 	Base
-------------------------------------------------------- */
.pageHeader {
	position: sticky 0 * *;
	z-index: 100;
}

/* 	Intall Callout (Red Bar)
-------------------------------------------------------- */
.installCallout {
	@mixin betterFontRendering;
	background: $gradient_red;
	color: #fff;
	padding: 14px 10px 14px 35px;
	display: flex;
	justify-content: center;
	position: relative;

	&__close {
		@mixin closeButton;
		position: absolute 50% * * 0;
		transform: translateY(-50%);
	}
	&__text {
		font: 400 14px/$fontHeadings_lineHeight $fontHeadings;
		letter-spacing: $fontHeadings_letterSpacing;
		padding-right: 20px;
		
		div {
			font: 500 10px/1.2 $fontDefault;
			margin-top: 2px;
		}
		
		@media (width > 360px) {
			white-space: nowrap;
		}
	}
	.btn {
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 112px;
		font-size: 13px;

		> * {
			padding: 0;
		}
	}
}

/* 	Header: Calc
-------------------------------------------------------- */
.header--calc {
	.header__inner {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		background: #fff;
		height: 78px;
		padding: 0 20px;
		border-bottom: 2px solid $color_gray-e8;
	}
	.header__logo {
		font: 700 12px/1.2 $fontDefault;
		letter-spacing: .04em;
		text-transform: uppercase;
		color: $color_gray-80;

		img {
			width: 148px;
			display: block;
			margin-bottom: 5px;
		}
	}
	.header__icons {
		button {
			display: flex;
			align-items: center;
			font-size: 0;
			letter-spacing: .06em;
			text-transform: uppercase;
			color: $color_gray-80;
			
			margin: 0;
			padding: 0;
			outline: 0;
			border: 0;
			border-radius: 0;
			background: transparent;
			cursor: pointer;
			transition: $linkTransition;

			* {
				transition: inherit;
			}
			svg {
				size: 18px;
			}
			&:hover,
			&:focus {
				color: $color_link;

				svg path,
				svg circle {
					stroke: $color_link;
				}
			}
			&:active {
				color: $color_linkActive;

				svg path,
				svg circle {
					stroke: $color_linkActive;
				}
			}
		}
	}
	@media (width > 550px) {
		.header__icons {
			display: flex;

			button {
				font: 700 13px/1.2 $fontDefault;

				svg {
					margin-right: 11px;
				}
				+ button {
					margin-left: 40px;
				}					
			}
		}
	}
	@media (width <= 550px) {
		.header__inner {
			justify-content: center;
			height: 62px;
		}
		.header__logo {
			font-size: 10px;

			img {
				width: 124px;
				margin: * auto 3px;
			}
		}
		.header__installBtn {
			position: absolute 50% * * 10px;
			transform: translateY(-50%);

			svg {
				size: 21px;
			}	
		}
		.header__settingsBtn {
			position: absolute 50% 10px * *;
			transform: translateY(-50%);
			font-size: 0;

			svg {
				margin: 0;
			}
		}
	}
}

/* 	Nav Bar
-------------------------------------------------------- */
.navBar {
	background: #fff;
	padding: 10px 20px;
	border-bottom: 2px solid $color_gray-e8;
	display: flex;
	align-items: center;
	min-height: 62px;

	.no-flexboxlegacy & {
		height: 62px;
	}
	
	button {
		font: 400 16px/$fontHeadings_lineHeight $fontHeadings;
		color: $color_gray-1c;
		padding: 0;
		outline: 0;
		border: 0;
		cursor: pointer;
		background: transparent;
		display: flex;
		transition: $linkTransition;
		
		&::before {
			content: url('../images/design/icon-back-arrow.svg');
			margin-right: 10px;
			margin-top: calc(-2em/16);
		}
		&:hover,
		&:focus {
			color: $color_linkHover;
		}
		&:active {
			color: $color_linkActive;
		}
	}
}