$resultsFooter_height: 				70px;
$mobileStickyBottomButton_height: 	70px;
$metricGroup_spacing-V: 			8px;
$resultsPanelGroup_spacing-V: 		26px;

@define-mixin mobileStickyBottomButton /* must be mixin since it's used in @media's */ {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: $mobileStickyBottomButton_height;
	border-top: 2px solid $color_red;
	background: #fff;
	padding: 10px;
	z-index: 5;

	.btn {
		size: 100%;

		> * {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
%metricWithLabel {
	color: $color_gray-1a;
	font-size: 24px;
	line-height: 1.2;

	div {
		@extend %label;
		margin: 0 0 2px;
	}
}

/* 	Info Box (Collapsable Message Box at Top of Main)
-------------------------------------------------------- */
.infoBox {
	@extend %formMessage;
	position: relative;

	&__text {
		padding-right: 20px; /* for Close "X" */
	}
	&__close {
		@mixin closeButton 7px, 1px, $color_gray-70;
		position: absolute 0 0 * *;
	}
}

/* 	Links to Technical Service Screen
	- Mobile version appears in .screen--results
	- Desktop version appears in .screen--calc
-------------------------------------------------------- */
.technicalServiceLink {
	margin-top: 20px;
	text-align: center;

	button {
		padding: 0;
		outline: 0;
		border: 0;
		background: transparent;
		font: 500 10px/1.2 $fontDefault;
		text-transform: uppercase;
		letter-spacing: .06em;
		color: $color_gray-66;
		display: inline-block;
		cursor: pointer;
		transition: $linkTransition;

		span {
			display: block;
			font: 400 14px/$fontHeadings_lineHeight $fontHeadings;
			margin-top: 8px;
			color: $color_red;
			transition: inherit;
		}
		&:hover,
		&:focus {
			&,
			span {
				color: $color_linkHover;
			}
		}
		&:active {
			&,
			span {
				color: $color_linkActive;
			}
		}
	}

	@media (width > $screen_stackResults) {
		&--mobile {
			display: none;
		}
	}
	@media (width <= $screen_stackResults) {
		&--desktop {
			display: none;
		}
	}
}

/* 	Main: Calc
-------------------------------------------------------- */
.main--calc {
	.cutQualityImage {
		margin-top: 8px;
		display: block;
		max-width: 137px;
		width: 100%;
	}

	.calc__viewResultsBtn {
		margin-top: 24px;
	}

	@media (width > $screen_stackResults) {
		background: #fff;
		border-left: $boxBorder;
		border-right: @border-left;
		padding: * 30px;
	}
	@media (width <= $screen_stackResults) {
		padding: * $box_padding-H-mobile calc($resultsFooter_height + 42px);
	}
}

.resultsFooter {
	position: fixed;
	bottom: 0;
	border-top: 2px solid $color_red;
	background: #fff;
	display: flex;
	height: $resultsFooter_height;
	justify-content: space-between;
	align-items: center;
	z-index: 5;

	&__metric {
		@extend %metricWithLabel;
		margin: 0 20px;
	}
	&__btn {
		font-size: 14px;
		flex: 1 1 auto;
		display: flex;
		align-self: stretch;
		align-items: center;

		span {
			display: flex;
			justify-content: center;
			align-items: center;

			&::after {
				content: url('../images/design/icon-back-arrow-white.svg');
				line-height: 0;
				transform: scaleX(-1);
				margin-left: calc(13em/16);
			}
		}
	}

	@media (width > $screen_stackResults) {
		display: none;
	}
}


/* 	Main: Results
-------------------------------------------------------- */
.main--results {
	overflow-x: hidden; /* hide horizontal overflow caused by the second results panel */

	.results__header {
		padding: * * 13px;

		.results__heading {
			@extend %h2;
		}
		.results__logo {
			width: 150px;
			display: block;
			margin: 0 auto 2px;
		}

		@media screen and (width > $screen_switchResultsHeader) {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.results__heading {
				padding-right: 30px;
			}
			.results__logo {
				display: none;
			}
		}
		@media print, (width <= $screen_switchResultsHeader) {
			@mixin box;
			padding: 15px 10px;
			margin-bottom: 13px;
			text-align: center;

			.results__heading {
				@mixin heading 700, 11px, 1.2, $fontDefault, $color_gray-80, uppercase, 0, .04em;
			}
		}
	}
	.resultsPanel {
		@mixin box;
		padding: 20px $box_padding-H-desktop;

		+ .resultsPanel {
			margin-top: 8px;
		}
		&__heading {
			@extend %h4;
			margin: 0 0 17px;
			padding-bottom: 7px;
			border-bottom: $headingBorder;
		}
		&__metricGroup {
			display: flex;
			flex-wrap: wrap;
		}
		&__metric {
			color: $color_gray-1a;
			font-size: 15px;
			line-height: 1.2;

			div {
				@extend %label;
				margin: 0 0 5px;
			}
		}

		@media (width <= $screen_stackResults) {
			padding: * $box_padding-H-mobile;
		}

		/* FIRST PANEL */
		&--first {
			border-top: 2px solid $color_red;

			.resultsPanel__upper {
				margin: calc(-1 * $resultsPanelGroup_spacing-V) -25px 0;

				> * {
					margin: $resultsPanelGroup_spacing-V 25px;
				}
			}
			.resultsPanel__metricGroup {
				margin: calc(-1 * $metricGroup_spacing-V) -10px;

				> * {
					padding: $metricGroup_spacing-V 10px;
				}
			}

			@media print, (width > 900px) {
				.resultsPanel__upper {
					display: flex;
				}
				.resultsPanel__upper > *:first-child,
				.resultsPanel__group--pierceTime {
					width: calc(265/832 * 100%);
					max-width: @width;
					flex: 1 0 auto;
				}
				.resultsPanel__group--perHour {
					width: 100%;
				}
				.resultsPanel__group--pierceTime {
					width: calc(265/780 * 100%);
					max-width: @width;
				}
			}
			@media screen and (width <= 900px) {
				.resultsPanel__metricGroup {
					> * {
						width: 50%;
					}
				}
			}
		}

		/* SECOND PANEL */
		&--second {
			/* overflow: hidden; */ /* Don't do this - will chop off tool tips */

			.resultsPanel__inner {
				margin: calc(-1 * $resultsPanelGroup_spacing-V) -25px;

				> * {
					margin: $resultsPanelGroup_spacing-V 25px;

					.resultsPanel__group + .resultsPanel__group {
						margin-top: $resultsPanelGroup_spacing-V;
					}
				}
			}
			.resultsPanel__metricGroup {
				margin: calc(-1 * $metricGroup_spacing-V) *;

				> * {
					padding: $metricGroup_spacing-V *;
					width: 50%;
				}
			}
			.resultsPanel__group--pump .resultsPanel__metric:first-child {
				width: 100%;
			}
			.resultsPanel__group--cutConfiguration .resultsPanel__metric:last-child {
				width: 100%;
			}

			@media (width > 1350px) {
				.resultsPanel__inner {
					margin: * -35px;

					> * {
						margin: * 35px;
					}
				}
			}

			@media print, (width > $screen_results-panel2-2col) {
				.resultsPanel__inner {
					display: flex;

					> * {
						width: 50%;
					}
				}
				.resultsPanel__metricGroup {
					margin: * -25px;

					> * {
						padding: * 25px;
						width: 50%;
					}
				}
			}
			@media screen and (width <= $screen_results-panel2-2col) {
				.resultsPanel__metricGroup {
					margin: * -10px;

					> * {
						padding: * 10px;
						width: 50%;
					}
				}
			}
		}
	}

	@media (width <= $screen_switchResultsHeader) {
		padding-bottom: calc($mobileStickyBottomButton_height + 42px); /* accommodate Print button fixed to bottom of screen */
	}
}

.results__printReport--footer {
	@mixin mobileStickyBottomButton;
	@media (width > $screen_stackResults) {
		display: none;
	}
}
.results__printReport--header {
	@media (width <= $screen_stackResults) {
		display: none;
	}
}



/* 	Main: Settings
-------------------------------------------------------- */
@define-mixin arrowList $fontSize: 18px, $arrowSize: 16px, $liPadding-V: 14px {
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		padding: $liPadding-V 0;
		margin: 0;
		border-bottom: 1px solid $color_gray-e6;

		&::before {
			display: none;
		}
		button {
			@mixin heading 400, $fontSize, $fontHeadings_lineHeight, $fontHeadings, $color_headings, none, 0;
			padding: 10px 18px 10px 10px;
			outline: 0;
			border: 0;
			background: transparent;
			cursor: pointer;
			text-align: left;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			transition: $linkTransition;

			&::after {
				content: '';
				background-image: url('../images/design/icon-back-arrow-red.svg');
				background-size: 100%;
				size: $arrowSize;
				margin-left: 15px;
				transform: scaleX(-1);
				transition: transform 200ms;
			}
			/* &:hover,
			&:focus {
				&::after {
					transform: scaleX(-1) translateX(calc(-8em/18));
				}
			} */
			&:hover,
			&:focus {
				color: $color_linkHover;
			}
			&:active {
				color: $color_linkActive;
			}
		}
		.settingsNavItem__text {
			max-width: 215px;
		}
		.settingsNavItem__description {
			@mixin heading normal, 12px, 1.5, $fontDefault, $color_gray-80, none, 5px 0 0, 0;
			display: block;
		}
	}
}

.main--settings {
	padding-top: 0;

	.settingsNav {
		@mixin arrowList;
	}
}
[class*="main--settings"] > * {
	max-width: calc($contentContainerMaxWidth + 2 * $defaultSpacing-H-desktop);
	margin: 0 auto;
}

.addItem {
	padding-top: 0;
	padding-bottom: calc($mobileStickyBottomButton_height + 42px);

	&__list {
		@mixin arrowList 15px, 14px, 6px;
	}
	&__noItemsMessage {
		margin: 20px 0;
		text-align: center;
	}
	&__footer {
		@mixin mobileStickyBottomButton;
	}
}

.saveItem {
	&__panel {
		@mixin box true;
	}
	&__metric {
		@extend %metricWithLabel;
	}
	&__btn {
		margin-top: 12px;
	}
	&__link {
		font: 400 14px/$fontHeadings_lineHeight $fontHeadings;
		letter-spacing: .06em;
		text-transform: uppercase;
		color: $color_link;
		display: block;
		margin: 25px auto 0;
		padding: 0;
		outline: 0;
		border: 0;
		background: transparent;
		cursor: pointer;
		transition: $linkTransition;

		&:hover,
		&:focus {
			color: $color_linkHover;
		}
		&:active {
			color: $color_linkActive;
		}
	}
}

.main--settings_currency {
	.currency__panel {
		@mixin box true;
	}
	.currency__btn {
		margin-top: 12px;
	}
	.currency__metric {
		@extend %metricWithLabel;
	}
}

/* 	Main: Install
-------------------------------------------------------- */
.main--install {
	& .installContent__downloadBtns {
		margin: 0 -5px;
		padding: 0;
		display: flex;
		flex-wrap: wrap;

		& li {
			margin: 5px;
			padding: 0;
		}
		& li::before {
			display: none;
		}
		& li img {
			display: block;
		}
		& + * {
			margin-top: 6px;
		}
	}
	& .installContent__heading {
		@extend %h1;
	}
	& .installContent__inner {
		@mixin box true;
		color: $color_gray-80;
		font: normal 14px/1.5 $fontDefault;
		max-width: $contentContainerMaxWidth;
		margin: * auto;
	}
	& .installContent__installBtn {
		margin-top: 4px;
		width: auto;
	}
}